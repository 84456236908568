<template>
  <komodo-modal
    :show-close="false"
    class="end-call-modal"
  >
    <template #body>
      <div class="end-call-title">
        {{ title }}
      </div>

      <div class="end-call-text-area">
        {{ subtitle }}
      </div>

      <div class="end-call-button-footer-wrapper">
        <komodo-button
          :size="ButtonSize.Small"
          :theme="ButtonTheme.Tertiary"
          data-test="cancel-button"
          @click="$emit('close')"
        >
          {{ cancelText }}
        </komodo-button>
        <komodo-button
          :size="ButtonSize.Small"
          :theme="ButtonTheme.Error"
          data-test="confirm-close-button"
          @click="confirm"
        >
          {{ confirmText }}
        </komodo-button>
      </div>
    </template>
  </komodo-modal>
</template>

<script lang="ts">
import KomodoModal from 'shared/components/KomodoModal.vue'
import KomodoButton from 'komodo2/components/KomodoButton.vue'

import { ButtonSize, ButtonTheme } from 'komodo2/types.ts'

export default {
  name: 'ConfirmCloseModal'
}
</script>

<script setup lang="ts">
interface Props {
  title: string
  subtitle: string
  confirmText: string
  cancelText?: string
}

withDefaults(defineProps<Props>(), {
  cancelText: 'Cancel'
})

const emit = defineEmits<{
  /**
   * Trigger Close event
   */
  (e: 'close'): void
  /**
   * Trigger Confirm Event
   */
  (e: 'confirm'): void
}>()

const confirm = () => {
  emit('confirm')
  emit('close')
}

</script>

<style lang="scss" scoped>
.end-call-modal {
  :deep(.komodo-modal-card) {
    width: 324px;
  }

  .end-call-title {
    @include k2-font-headline-headline-2;
  }

  .end-call-text-area {
    @include font-paragraph-p1-baseline;
    @include color-gray-black;

    margin-bottom: 28px !important;
  }

  .end-call-button-footer-wrapper {
    display: flex;
    justify-content: flex-end;

    .k2-button {
      margin-left: 8px;
    }
  }
}
</style>
